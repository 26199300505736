exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-browsernotsupported-js": () => import("./../../../src/pages/browsernotsupported.js" /* webpackChunkName: "component---src-pages-browsernotsupported-js" */),
  "component---src-pages-callback-hooverlogin-js": () => import("./../../../src/pages/callback/hooverlogin.js" /* webpackChunkName: "component---src-pages-callback-hooverlogin-js" */),
  "component---src-pages-callback-index-js": () => import("./../../../src/pages/callback/index.js" /* webpackChunkName: "component---src-pages-callback-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-babel-prodotto-js": () => import("./../../../src/templates/babel-prodotto.js" /* webpackChunkName: "component---src-templates-babel-prodotto-js" */),
  "component---src-templates-compare-page-js": () => import("./../../../src/templates/compare-page.js" /* webpackChunkName: "component---src-templates-compare-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-line-js": () => import("./../../../src/templates/page-line.js" /* webpackChunkName: "component---src-templates-page-line-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-helpers-menu-js": () => import("./../../../src/components/helpers-menu.js" /* webpackChunkName: "slice---src-components-helpers-menu-js" */),
  "slice---src-components-navigation-js": () => import("./../../../src/components/navigation.js" /* webpackChunkName: "slice---src-components-navigation-js" */),
  "slice---src-components-top-main-menu-js": () => import("./../../../src/components/top-main-menu.js" /* webpackChunkName: "slice---src-components-top-main-menu-js" */)
}

